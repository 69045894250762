/* global.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body,
  html {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    color: #fff;
  }
}
