/* Central Logo */
.logo-image {
  position: absolute;
  top: -650%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  max-width: 730.5px;
  height: auto;
  aspect-ratio: 730.5 / 662;
  transition: none; /* Ensure no accidental movement during animations */
  margin-bottom: 0.5cm;
}

/* Responsive adjustments for the central logo */
@media (max-width: 1200px) {
  .logo-image {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .logo-image {
    width: 60%;
    top: 80%;
  }
}

@media (max-width: 480px) {
  .logo-image {
    width: 90%;
    max-width: 430.5px;
    height: auto;
    top: 80%;
    aspect-ratio: 430.5 / 362;
  }
}

/* Hero Container */
.hero-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Content Wrapper */
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Media Queries for Content Container */
@media (max-width: 768px) {
  .content-container {
    bottom: 12%;
  }
}

@media (max-width: 480px) {
  .content-container {
    bottom: 10%;
  }
}

/* Button Styles */
.btn-primary {
  padding: 10px 10px;
  background-color:#ff4500;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  position: center;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  font-family: sans-serif;
  border-style: dashed;
  border: #ffffffab;
  border-style: outline;
}

.btn-primary:hover {
  background-color: #ff4500;
}
.btn-primary:focus,
.btn-primary:active {
  outline: glow; /* Prevent focus outline from causing layout shift */
  position: center; /* Prevent the button from affecting its surroundings */
}

.hidden {
  display: none;
}

.visible {
  display: inline-block;
}

/* Rocket Animation */
.rocket {
  position: absolute;
  width: 120px;
  height: auto;
  transition: transform 0.3s ease-in-out;
  z-index: 2;
}

.rocket.launch {
  animation: launch 2s forwards;
}

@keyframes launch {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(0, -100vh) rotate(45deg);
  }
}

/* Speech Bubble */
.speech-bubble-container {
  position: absolute;
  left: 15%;
  top: 40%;
  z-index: 2;
  width: 250px;
  max-width: 90%;
}

.speech-bubble {
  background: white;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.speech-bubble.visible {
  opacity: 1;
  transform: translateY(0);
}

.speech-bubble:after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 20px;
  border-width: 15px 15px 0;
  border-style: solid;
  border-color: white transparent;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .speech-bubble-container {
    width: 200px;
    left: 10%;
  }

  .btn-primary {
    font-size: 1rem;
    padding: 8px 14px;
  }
}

@media (max-width: 480px) {
  .logo-image {
    top: 20%;
    width: auto;
    height: auto;
  }

  .btn-primary {
    padding: 8px 14px;
    background-color: #ffffff1a;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1.2rem;
  }

  .speech-bubble-container {
    width: 180px;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
  }
}
