.content-wrapper {
  width: 100%;
  max-width: 700px;
  margin: auto;
  padding: 20px;
  position: relative;
}

.astronaut-container {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 50%;
  max-width: 374px;
  z-index: 0;
  pointer-events: none;
}

.astronaut-image {
  width: 100%;
  height: auto;
}

.styled-contact {
  position: relative;
  z-index: 2;
  margin-left: 15%;
}

.styled-contact *,
.styled-contact *:before,
.styled-contact *:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.screen {
  position: relative;
  background: rgba(62, 62, 62, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 30px;
  padding: 20px;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: rgba(77, 77, 79, 0.8);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}

.screen-header-button.close {
  background: #ed1c6f;
}

.screen-header-button.maximize {
  background: #e8e925;
}

.screen-header-button.minimize {
  background: #74c54f;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.screen-body {
  display: flex;
  flex-direction: column;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ff5722;
  font-size: 24px;
  margin-bottom: 20px;
}

.app-form-group {
  margin-bottom: 15px;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #ff5722;
  color: #ddd;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.app-form-control::placeholder {
  color: #999;
}

.app-form-control:focus {
  border-bottom-color: #ddd;
}

.app-form-button {
  background: none;
  border: none;
  padding: 10px 20px;
  color: #ff5722;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.app-form-button:hover {
  color: #fff;
  background-color: rgba(255, 87, 34, 0.1);
  border-radius: 5px;
  transition: all 0.3s;
}

@media (max-width: 1200px) {
  .astronaut-container {
    width: 40%;
  }
  .styled-contact {
    margin-left: 10%;
  }
}

@media (max-width: 992px) {
  .astronaut-container {
    width: 30%;
  }
  .styled-contact {
    margin-left: 5%;
  }
}

@media (max-width: 768px) {
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .astronaut-container {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    max-width: 200px;
    z-index: 1;
  }
  .styled-contact {
    margin-left: 0;
    width: 100%;
  }
  .screen {
    background: rgba(62, 62, 62, 0.9);
  }
  .screen-body {
    padding: 40px 40px 40px 80px;
  }
}

@media (max-width: 480px) {
  .astronaut-container {
    width: 60%;
    left: -10px;
    top: 5px;
  }
  .app-title {
    font-size: 20px;
  }
  .app-form-button {
    padding: 8px 16px;
    font-size: 12px;
  }
  .screen-body {
    padding: 20px 20px 20px 60px;
  }
}
@keyframes slideInAstronaut {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.astronaut-image {
  animation: slideInAstronaut 1.5s ease-out forwards;
  animation-iteration-count: infinite
}